@keyframes animateDash {
  to {
    stroke-dashoffset: 1000;
  }
}
:root {
  --map-tiles-filter: brightness(0.7) invert(1) contrast(5) hue-rotate(100deg) saturate(0) brightness(0.7);
}

@font-face {
  font-family: 'Franklin Gothic Medium';
  font-weight: 600;
  src: url("Fonts/Franklin Gothic Medium Regular.otf");
}
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800&family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@keyframes inAnimation {
  0% {
    opacity: 0;
    max-height: 0px;
    visibility: hidden;
    margin: 0px;
  }
  100% {
    opacity: 1;
    max-height: 400px;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    margin: 0px;

  }
}
.item-enter {
  opacity: 0;
  max-height: 0px;
  overflow:hidden;
}
.item-enter-active {
  opacity: 1;
  max-height: 600px;
  overflow:hidden;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
  max-height: 0px;
  overflow:hidden;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
  max-height: 0px;
  overflow:hidden;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 25px;
  height: 12px;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  border:1px #fff solid;
  display: inline-block;
  vertical-align:middle;
  margin-right: 7px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 14px;
  transition: 0.2s;
  border:1px #fff solid;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 14px;
}
.lazy-load-image-background > img {
  opacity:0;
  transition: all 2s;
}
.lazy-load-image-background.loaded > img {
  opacity:1;
  transition: all 2s;

}
.break {
  flex-basis: 100%;
  height: 0;
  margin-top: 15px;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;

  transition: opacity 250ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}
.App {
  width: 100%;
  height: 100%;
  padding:0;
  margin:0;
}
body.light, body.light a, body.light .header, body.light .create_page .pre_prompt, body.light .create_page button, body.light .create_page .expand, body.light .create_page input {
  color: #000;

}
body.light .menu .header, body.light .menu a {
  color: #000;
}
body.light {
  background-color: #eee;
  background-attachment: fixed;
  transition: background-color 0.5s ease;
}
body.raspi_display {
  transform: scaleY(0.96);
  transform-origin: top;
}
body.raspi_display .create-widget, body.raspi_display .header, body.raspi_display .menu {
  display: none !important;
}
body.raspi_display .new-grid {
  margin-top: 50px !important;
}
body.raspi_display .img-box {
  margin-bottom: 20px !important;
}
body.raspi_display .handle{
  font-size: 17px  !important;
}
a {
  color: white;
  text-decoration: none;
}
body {
  background: black;
  color: #fff;

}
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@300;400;500;600;700&family=Arimo:wght@500&family=Inter:wght@300;400;500;600&display=swap');

* {
  font-family:'Inter', sans-serif;

}
.menu {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  user-select: none;
  display: ;
  width: 100%;
  pointer-events: none;
}
.menu .menu_toggle {
  display: none;
}
.menu ul {
  margin:0;
  padding:0;
  list-style:none;
  display: none;
}
.menu ul li {
  display: ;
  color: black;
  font-weight:500;
  text-decoration: ;
  font-weight: ;
  font-size: 20px;
  padding: 10px;
  line-height: 100%;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
}

.menu ul li a {
  transition: all 0.5s ease;
  color: white;
}
.menu ul li a.active {

  color: red;
}
.menu ul li a:hover {
  color:red;
}
.menu .header {
  display: ;
  color: black;
  font-weight:700;
  text-decoration: ;
  font-style: italic;
  font-size: 20px;
  border: 1px #000 solid;
  background: white;
  padding: 0px;
  border-radius: 10px;
  line-height: 100%;
  display: inline-block;
  margin: 20px;
  overflow: hidden;
  pointer-events: all;
  transition: 0.5s all ease-in-out;

}
.menu .header.closed .submenu {
  opacity: 0;
  pointer-events: none;
  max-height: 0px !important;
  transition: 0.5s all ease-in-out;
}
.menu .header.open .submenu {
  opacity: 1;
  pointer-events: all;
  transition: 0.5s all ease-in-out;
  max-height: 500px !important;
}
.menu .header > .toggle {
  color: gray;
  font-size: 15px;
  padding-left: 12px;
  padding-right: 10px;
  display: inline-block;
  width: 15px;
  cursor: pointer;
}
.menu .header > .logo {
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 1px #bbbbbb solid;
  padding-left: 12px;
  padding-right: 10px;

  display: inline-block;

}
.menu .header .submenu {
  text-align: left !important;
}
.menu .header .submenu .item {
  border-top: 1px #bbbbbb solid;
  background: ;
}
.menu .header .submenu .item > div {
  display: inline-block;
  vertical-align: middle;
}
.menu .header .submenu .item .icon {
  width: 15px;
  font-size: 15px;
  padding-right: 10px;
  color: gray;
  line-height: 100%;
  padding-left: 12px;

}
.menu .header .submenu .item .label {
  font-style: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.menu .queued {
  font-weight: 500;
  padding: 20px;
  float: right;
  font-size: 15px;
  color: gray;
}
.background-grid {
  width: 100%;
  height: 100%;
  padding:0;
  margin:0;
}
.background-grid .hoverInfo {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9999;
  width: 300px;
  line-break: anywhere;
  overflow: hidden;
  display: none;
}
.background-grid .hoverInfo ul {
  list-style:none;
  padding:0;
}
.background-grid .hoverInfo ul li {
  font-size: 17px;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: red;
}
.background-grid .hoverInfo ul li.title {
  font-weight: 600;
}
.background-grid .frame {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
}
.background-grid .frame > .inner {
  width: 100vw;
  height: auto;
  position: relative;
  overflow: ;
  top: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 10fr));
  grid-gap: 0px;
  
}
.frame > .inner > div {
  width: 100%;
}
.background-grid .img-box {
  line-height: 0px;
}
.background-grid img {
  width: 100%;
  height: auto;
  z-index: 1;
}
.background-grid img:hover {
  transform:scale(2);
  transition: all 0.2s ease-in-out;
  z-index: 99999999;
  position: relative;
  transition-delay: 100ms;
}
/**NEWGRID**/
.new-grid {
  width: 98%;
  height: 100%;
  padding:0;
  margin:0 auto;
  margin-top: 60px;
  margin-bottom: 2%;
}
.tooltip {
  position: fixed; 
  z-index: 99999999999;
  top: 20px;
  right: 20px;
  vertical-align: middle;
  user-select: none;
  line-height: 100%;
}
.tooltip > .item {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
.tooltip .nsfw_filter {
  font-size: 13px;
  margin-top:2px;
  color: gray;
  cursor: pointer;
  transition: all 0.3s;

}
.tooltip .nsfw_filter:hover {
  color: white;
  transition: all 0.3s;

}
.tooltip .nsfw_filter.active {
  color: red;
}
.new-grid .slider {
}
.new-grid .slider input {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}
.new-grid .slider .value {
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.new-grid.blur .frame {
  filter: blur(10px);
}
.new-grid .frame {
  width: 100%;
  height: auto;
  overflow: ;
}
.new-grid .frame > .inner {
  max-width: 600px;
  height: auto;
  transform-origin: center center;
  position: relative;
  overflow: ;
  margin: 0 auto;
}
.new-grid .frame.feed .img-box {
}
.new-grid .frame.feed .img-box.text {
  font-size: 22px;
}
.new-grid .img-box .profile_header {
  line-height: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  
}
.new-grid .img-box .pfp {
  vertical-align: middle;
  line-height: 100%;
  position: absolute;
  left: -40px;
  top: 7px;
}
.new-grid .img-box .pfp img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.social_icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 12px;
  line-height: 100%;
}
.new-grid .content {
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  width: calc(100% - 2px) !important;
}
.new-grid .content > .body {
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  word-wrap: break-word;
  max-width: 100%;
  line-height: 150%;
  margin-top: 5px;
}
.new-grid .content > .body img {
  border-radius: 10px;
}
.new-grid .img-box .profile_header .handle {
  font-weight:600;
}
.new-grid .img-box .profile_header .handle, .new-grid .img-box .profile_header .time {
  display:inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.new-grid .img-box .profile_header .handle {font-size:17px;}
.new-grid .img-box .profile_header .time {font-size: 14px;color:gray;}
.new-grid .img-box .lazyload-wrapper img {
  border-radius:10px;
  border:1px #ccc solid;
  width:100%;
  margin-top: 15px;
}
.new-grid .frame.feed .caption {
  padding-top: 15px;
}

.list .item label {
  padding-right: 5px;
  color: gray;
  font-size: 13px;
  text-transform: uppercase;
}
.list .item {
  font-size: 15px;
  margin-bottom: 10px;
  line-height:150%;
}
.list .item svg {
  vertical-align: middle;
}
.new-grid .img-box {
  border-radius: 3px;
  font-size: 10%;
  font-size: 18px;
  font-weight: 400;
  line-height: 200%;
  margin: 0 0 30px;
  position: relative;
  text-align: center;
  text-align: left;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.new-grid::after {
  /*content: ' ';
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-size: 3.5px 2.5px, 3.5px 2.5px;
  background-position: 0, 7px 0px;
  background-image: radial-gradient(circle, #000, rgba(255, 255, 255, 0) 40%), radial-gradient(circle, #000, rgba(255, 255, 255, 0) 40%);
  mix-blend-mode: multiply;
  top: 0;
  left: 0;
  z-index: 99999;*/
}
.new-grid .img-box:hover {
  filter: none;
  z-index: 99999999;

  transition: all 0.2s ease-in-out;
}
.new-grid .img-box.nsfw {
}
.new-grid .img-box.nsfw img {
  filter:blur(5px);
}
.feed .img-box.nsfw img {
  filter:blur(10px);
}
.inner.nsfw img {
  filter:blur(0px) !important;
}
.new-grid img {
  width: 100%;
  height: auto;
  z-index: 1;
}

.new-grid .img-box .caption {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 10px;
}
.new-grid .img-box:hover a {
}
.create_page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
}
.create_page .guide {
  width: 500px;
  position: absolute;
  top:50%;
  left: 50%;
  transform:translate(-50%, -50%) scale(0);
  font-size: 17px;
  background: white;
  border-radius:4px;
  border: 1px #ccc solid;
  padding: 20px;
  line-height: 150%;
  z-index:9999;
  opacity:0;
  visibility: hidden;
  transition: 0.2s all ease-in-out;
  max-height: 80vh;
  overflow:scroll;
}
.create_page .guide.visible {
  opacity:1;
  transform:translate(-50%, -50%) scale(1);
  visibility: visible;
}
.create_page .pre_prompt {
  background: transparent;
  font-size: 30px;
  border: 0;
  color: white;
  vertical-align: middle;

}
.create_page .form_outer {
  width: 650px;
  display: ;
}
.create_page .form {
  display:flex;
  align-items: center;
  justify-content: center;

}
.create_page .form_advanced {
  display:flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  max-height: 0px !important;
  overflow:hidden;
  justify-content: left;
  transition: all 0.2s ease-in-out;
}
.create_page .form_advanced.visible {
  max-height: 200px !important;
  opacity:1;
  padding-top: 20px;
}
.create_page .form_advanced .input {
  background: transparent;
  font-size: 15px;
  padding: 10px;
  border: 0;
  border: 1px #ccc solid;
  border-radius: 4px;
  color: white;
  width: 350px;
  vertical-align: middle;
}
.caption_form button {
  margin-left: 10px;
}
.caption_form button.inactive {
  opacity: 0.5;
  cursor: not-allowed;
}
.caption_form button.inactive:hover {border-color:white;}
.create_page .form button, .caption_form button, .expand {
  border: 1px #fff solid;
  border-radius: 3px;
  background: transparent;
  font-size: 15px;
  padding: 10px;
  margin-left: 15px;
  position: relative;
  cursor:pointer;
  color: white;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.expand {
  position: relative;
  width: 20px;
  text-align: center;
}
.create_page .form button:hover, .caption_form button:hover, .expand:hover {
  border-color:hsl(0, 0%, 75%);
}
.create_page .form button {
  vertical-align: middle;
}
.create_page .prompt_input {
  background: transparent;
  font-size: 30px;
  border: 0;
  border-bottom: 0px red solid;
  color: white;
  width: 350px;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;

}
.create_page .image_container {
  width: 512px;
  margin: 0 auto;
  display: ;
  text-align: center;
  /*margin-top: 50px;
  margin-bottom:50px;*/
}
.create_page .image_container img {
  width: 100%;
}
.create_page .image_container .left, .create_page .image_container .left img {
  width: 300px;
  
}
.create_page .image_container .right {
  flex: 1;
  margin-top: 20px;
}
.create_page .image_container .right .metadata_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.create_page .image_container .right .metadata_list .item {
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  
}
.create_page .image_container .right .metadata_list .item input {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  border: 0;
  border: 3px #989898 ridge;
  border-radius: 3px;
  background: none;
  outline: none;
  color: #fff;
  padding: 10px;
  flex: 1;
}
.create_page .image_container .right .metadata_list .item input::placeholder {
  color: ;
}
.create_page .image_container .right .metadata_list .item.location {
  display: block;
  width: 100%;
  display: none;
}
.create_page .image_container .right .metadata_list .locationWrapper {
  width: 100%;
  max-height: 0px !important;
  overflow: hidden;
  display: block;
  transition: 0.2s all ease-in-out;
}
.create_page .image_container .right .metadata_list .locationWrapper.visible {
  max-height: 500px !important;

}
.create_page .image_container .right .metadata_list .locationWrapper .leaflet-container {
  width: 100%;
  height: 400px;
}
.create_page .image_container .caption_form {
  margin:  10px 0 10px 0;
  display:flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  
}
.create_page .image_container .caption_form .caption_input {
  background: transparent;
  font-size: 15px;
  border: 0;
  color: white;
  flex: 1 1;
  border: 1px #fff solid;
  border-radius: 3px;
  padding: 10px;
}
.create_page .image_container .caption_form .expand {
  width: auto;
}
.create_page .image_container .caption_form .checkbox_container {
  display : flex;
  align-items : center;
  margin-right: 20px;
}
.create_page .image_container .caption_form .checkbox_container #checkbox {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  color: white;
  appearance: none;
  background-color: transparent;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid white;
  border-radius: 3px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 0px;
}
.create_page .image_container .caption_form .checkbox_container #checkbox:checked::before {
  transform: scale(1);
}
.create_page .image_container .caption_form .checkbox_container .checkbox_label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.create_page .submitted {
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
}
.create_page .submitted .submitted_text {
  font-size: 30px;
  display: ;
  margin-bottom: 30px;
}
.create_page .submitted .link {
  border: 1px #fff solid;
  border-radius: 3px;
  background: transparent;
  font-size: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  cursor:pointer;
  color: white;
  display: inline-block;
}
.create_page .error .errorreset {
  width: auto;
  display: inline-flex;
}
.info_page {
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
}

.info_page .text {
  width: 700px;
  background: transparent;
  font-size: 22px;
  font-weight: 500;
  border: 0;
  border-bottom: 0px red solid;
  line-height: 150%;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 70px;
  color: gray;
}
.info_page .text a {
  color: red;
  text-decoration: underline;
}
.info_page .text headline {
  font-weight: 600;
  font-size: 17px;
  color: black;

}
.info_page .text black {
  color: black;
}
.loader.small {
  width: 60px;
  height: 55px;
  position: relative;
  text-align: center;
  transform: none;
  top: 0;
  left: 0;
  margin: 0 auto;
}
.loader.small .loader_inner{
  width: 40px;
  height:40px;
}
.loader.small .lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.loader.small .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 2px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}
.loader {
  width: 80px;
  transform: translate(-50%, -50%);
  position: absolute;
  top:50%;
  left:50%;
}
.loader_inner {
  width: 80px;
  height: 80px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 2px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.popup {
  z-index:99;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(0, 0, 0, 0.633);
  width: 900px;
  border-radius:10px;
}
.popup img {
  width: 512px;
}
.popup .caption {
  margin-bottom: 20px;
  font-size: 20px;
}
.popup .image {
  float: left;
  line-height: 0px;
}
.popup .metadata {
  padding: 30px;
  float: right;
  width: calc(100% - 512px - 60px);
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader svg {
  width: 100%;
  stroke-dasharray: 50;
  animation: animateDash 10s linear forwards infinite;
}
.loader svg path {
}
.background-list {
  margin: 0 auto;
  margin-bottom: 100px;
  width: 512px;
}
.background-list .post {
  width: 100%;
  margin-top: 100px;

}
.single_page {
  width: 512px;
  position: relative;
  margin: 0 auto;
  padding: 0%;
  border-radius:0px;
  overflow:hidden;
  margin-top:70px;
  margin-bottom:50px;
}
.single_page .left {
  width: 100%;
  text-align: center;
  position: relative;
}
.single_page .left .image_container {

}
.single_page .left .image_container img {
}
.single_page .right {
  padding: 0px;
  line-height: 150%;
  width: calc(100%);
  position: ;
  top:0;
  right: 0;
}

.single_page .right .caption {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
}
.single_page .right .list .item svg {
  vertical-align: middle;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.single_page .lazyload-wrapper img {
  animation: fadeIn ease 0.5s;
}
.single_page .lazyload-wrapper .lazyload-placeholder {
  width: 512px;
  height: 512px;
  overflow:hidden;
}
.single_page img {
}
.single_page .metadata {
  float: both;
  overflow: hidden;
}
.single_page .text {
  margin-top: ;
}
.single_page .text cat1 {
  color: red;
    -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: white;
}
.single_page  .metadata .like {
  text-align: left;
  line-height: 0px;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
  display:inline-block;
  
}
.single_page  .metadata .timestamp {
  display:inline-block;
  line-height: 100%;
}
.single_page  .metadata .like::after {
  content:"";
  width: 1px;
  height: 13px;
  border-right: 1px #ccc solid;
  margin: 0px 15px 0px 15px;
  display:inline-block;
  vertical-align: middle;
}
.single_page .like svg {
  display: inline-block;
  vertical-align: middle;
  fill: red;
}
.single_page .like svg path {
  stroke: #000;
  stroke-width: 1px;
  stroke-linejoin: round;
}
.single_page .image_container {

}
.single_page .image_container .heart_trigger {
  position: absolute;
  font-size: 120px;
  z-index: 99;
  top: 50%;
  left: 50%;
  color: red;
  transform: translate(-50%,-50%);
  opacity:0;
}
.animation_trigger {
  animation-name: heartAnimation;
  animation-duration: 0.7s;
}
.helper {
  position: absolute;
  top: -50px;
  left: 50%;
  border: 1px hsl(0, 0%, 80%) solid;
  background: white;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  transform: translateX(-50%);
  display: none;
}
input[type=range] {
  -webkit-appearance: none;
  background-color: transparent;

}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #fff;
  border: 0.5px solid #000;
  border-radius: 10px;
}
input[type=range]::-webkit-slider-thumb {
  border: 0.5px solid #000000;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
@keyframes heartAnimation {
  0% {
    opacity: 0;
    transform:translate(-50%,-50%) scale(0%);
  }
  10% {

  }
  15% {
	}
	30% {
	  
	}
	40% {
    opacity: 1;
    transform:translate(-50%,-50%) scale(100%);
    opacity: 1;
	}
  100% {
    opacity: 0;
  }
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
.leaflet-tile {
  filter:var(--map-tiles-filter, none) !important;
}
.AsyncImage-root {
  margin-top: 0px;
  margin-left: 0px;
}
.content img {
  margin-bottom: -15px;
  
}
div.header {display: none;}
@media screen and (max-width: 700px) {
  body.raspi_display .new-grid .img-box .pfp {
    vertical-align: middle;
      line-height: 100%;
      position: absolute;
      left: -40px;
      top: 7px;
  }
  body.raspi_display .new-grid .img-box .pfp img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }

  .create-widget{
    width: 100% !important;
  }
  .single_page {
    width: 100%;
    margin-top:55px;
    margin-bottom: 4%;
  }
  .single_page img {
    width: 100%;
  }
  .single_page .text {

  }
  .info_page .text {
    width: 80%;
    padding: 10%;
    position: relative;
    font-size: 20px;
    padding-top:30px;
  }
  textarea:focus, input:focus{
    outline: none;
}
  body {
  }
  .new-grid {
    width: 80%;
    margin-top:55px;
    padding-left: 30px;
  }
  .new-grid .img-box {
    width: 100%;
  }
  .new-grid .img-box.anim {
    animation: fadeIn 2s linear;
  }
  .new-grid .img-box .meta, .single_page .text {
    padding-left: 5%;
    padding-right: 5%;
  }
  .new-grid .img-box .meta .caption {
    line-height:200%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .new-grid .img-box .profile_header .handle, .new-grid .img-box .profile_header .time {
    margin-right: 5px;
    font-size: 15px;
  }
  .create_page .form_outer {
    width: 80%;
  }
  .create_page .form {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .create_page .image_container {
    width: 100%;
  }
  .create_page .caption_form {
    width: 90%;
    min-width: 0;
    margin-left:5% !important;
    overflow:hidden;
  }
  .create_page .image_container .caption_form .caption_input {
    min-width:0;
  }
  .pre_prompt {
    font-size: 20px !important;
  }
  .prompt_input {
    font-size:20px !important;
    border: 1px gray solid !important;
    border-radius: 4px;
    padding: 10px;
  }
  .menu {
    width: 100%;
    transition: all 0.2s ease-in-out;
    user-select: none;
    z-index:999999999;
    left:0;
    right:0;
    position: fixed;
    overflow: hidden;
  }
  .menu .header {
    margin: 0px;
  }
  .menu_toggle {
    display: inline-block !important;
    cursor:pointer;
    font-size: 20px;
    display:none !important;
  }
  .menu .queued {
    display: none;
  }
  .menu ul {
    transition: all 0.2s ease-in-out;
    padding:0px !important;
    line-height:250%;
    user-select: none;
  }
  .menu.closed ul {
    opacity:0;
    max-height: 0px;
    overflow:hidden;
  }
  .menu.open {
    background:#000;
    padding-bottom: 20px;
    z-index:999999999;
  }
  .menu.open ul {
    opacity:1;
    max-height: 300px;
    overflow:hidden;
    border-radius:0px;
    padding: 0px;
    padding-top:10px !important;
    width: 100%;
    }
  .menu ul li {
    font-size: 25px;
    user-select: none;
    display:block;
  }
  .menu ul li.header {display: none;}
  .menu ul li a {
    -webkit-text-stroke-width: 0px !important;
    color: white;
    user-select: none;
  }
  .menu {
    
  }
  .create_page .submitted .link {
    margin-bottom: 20px;
  }
  .tooltip .toggle {
    display: none;
  }
  .new-grid .slider {
    display: none;
  }
  .tooltip .nsfw_filter {
    margin-top:0px !important;
    line-height: 0px !important;
  }
  .tooltip {
    top: 4vw;
    right:4vw;
    margin: 0px !important;
    padding: 0px !important;
    line-height: 100%;
  }
  div.header {
    display: ;
    color: white;
    font-style:italic;
    font-weight: ;
    text-align:center;
    position: relative;
    left:50%;
    transform: translateX(-50%);
    display: block;
    margin-top: 20px !important;
  }
  .App, .home {
    overflow-y:hidden;  
  }
  .tooltip {
    z-index: 999999999999999999999999999;

  }
  body.light .new-grid {
    width: 83%;
    padding-left: 10px;
    margin-top: 85px;
  }
  body.light .new-grid .img-box {
    margin-bottom: 30px;
    width: 100% !important;
    overflow: ;
  }
  body.light .new-grid .content {
    padding: 0px;
  }
  body.light .new-grid .pfp img {
    width: 40px;
    height: 40px;
  }
  body.light .new-grid .profile_header {
    padding-left: 25px;
  }
  body.light .new-grid .body {
    padding-left: 25px;
  }
  body.raspi_display .new-grid {
    width: 80%;
    padding-left: 20px;
  }
  body.raspi_display .new-grid .profile_header {
    padding-left: 20px;
  }
  body.raspi_display .new-grid .body {
    padding-left: 20px;
  }
  body.light .new-grid .pfp {
    left: -25px;
    top: 12.5px;
  }
  .create-widget .pfp, .create-widget .pfp img {
    width: 40px !important;
    height: 40px !important;
  }
}
.create-widget {
  width: 600px;
  border: 1px #000 solid;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 50px;
  background: white;
  position:relative;
  user-select: none;
  filter: blur(5px);
}
.create-widget.disabled > * {
  pointer-events: none;
}
.create-widget.disabled > .pfp {
  opacity: 1;
}
.create-widget img {
  border-radius: 10px;
}
.create-widget .img {
  position: relative;
}
.create-widget .img.default {
  margin-top: 20px;

}
.create-widget .img .close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
  color: white;
  cursor: pointer;
}
.create-widget.loading{
}
.create-widget .pfp {
  vertical-align: middle;
  line-height: 100%;
  position: absolute;
  left: -40px;
  top: 7px;
  overflow: hidden;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  user-select: none;
  cursor: pointer;
}
.create-widget .pfp:hover .shuffle_icon {
  opacity: 1;
}
.create-widget .pfp .shuffle_icon {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: ease-in-out all 0.1s;
}
.create-widget .pfp .shuffle_icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}
.create-widget .pfp img {
  width: 50px;
  height: 50px;
}
.create-widget .sec {
  width: 100%;
  overflow:hidden;
}
.create-widget .sec input {
  border: 0;
  width: 100%;
  outline:none;
  font-size: 15px;
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 10px !important;
}
.create-widget .image-div  {
  display: flex;
  align-items: center;

}
.create-widget .image-div .pre_placeholder { font-size: 15px; font-weight: bold; padding-right:5px;}
.create-widget .success-div {
  text-align: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
}
.create-widget .success-div success {
  font-size: 40px;
}
.create-widget .inner {
  width: 100%;
}
.create-widget .inner .icons {
  vertical-align: middle;
  padding: 20px;
  overflow: hidden;

}
.create-widget .inner .icons .icon {
  vertical-align: middle;
  display: inline-block;
  line-height: 100%;
  border:1px #000 solid;
  border-radius: 5px;
  padding:10px;
  font-size: 12px;
  margin-right: 10px;
  margin-top:15px;
}
.create-widget .inner .icons .icon.x svg, .create-widget .inner .icons .icon.ig svg {
  margin-right: 0px;
}
.create-widget .inner .icons .icon.active {
  background: black;
  color: white;
}
.create-widget .inner .icons .icon svg {
  margin-right: 5px;
  vertical-align: middle;
}
.create-widget .inner .icons .icon.button svg {
  margin-right: 0px;
}
.create-widget .inner .icons .icon > * {
  vertical-align: middle;
  display: inline-block;
}
.create-widget .button {
  vertical-align: middle;
  line-height: 100%;
  border-radius: 5px;
  padding:10px;
  font-size: 12px;
  float: right;
  background:black;
  color: white;
  margin-right: 0px !important;
  position: relative;
}
.create-widget .button.post {
  padding: 0px !important;
}
.create-widget .button.post.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.create-widget .button.post .inner {
  width: auto;
  padding: 10px;
}
.create-widget .button.post .dropdown_placeholder {
  padding-right: 10px;
  border-left: 1px gray solid;
  padding-left: 10px;
}
.create-widget .button.post .dropdown {
  opacity: 0;
  background: black;
  transition: all 0.5s ease;
  transform: scaleX(0);
  max-width: 0px;
  max-height: 0px;
  overflow:hidden;

}
.create-widget .post-section {
  border-top: 1px gray solid;
  margin-top: 20px;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: -20px;
  padding-right: 20px;
  padding-top: 5px;
}
.create-widget .post-section .icon {
}
.create-widget .post-section .item svg {
  margin-right: 0px !important;
}
.create-widget .post-section .item socialicon {
  margin-right: 5px;
}
.create-widget .button.post .dropdown.visible {
  opacity: 1;
  max-width: 200px;
  max-height: 50px;
  transform: scaleX(1);
  height: auto;
  left: 0px;
  transition: all 0.5s ease;

}
.create-widget .button.post .dropdown .item {
  padding: 7px 10px 7px 10px;
  font-size: 11px;
  display: inline-block;
}